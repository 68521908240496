/* - - - - - - - - - - - - common styles - - - - - - - - - - - - - */
@font-face {
  font-family:'Lato';
  src: url('./fonts/LatoBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

html {
  box-sizing: border-box;
}

html *,
html *::before,
html *::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  fill: currentColor;
}

html,
body,
#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

input:-webkit-autofill {
  color: #000;
  -webkit-text-fill-color: #000;
  transition: background-color 999999s;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  color: #000;
  -webkit-text-fill-color: #000;
  transition: background-color 999999s;
}

.page-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* - - - - - - - - - - - - Ant Design - - - - - - - - - - - - - */
.ant-typography {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}

.ant-table.ant-table-small {
  font-size: 16px;
}
.ant-table.ant-table-small .ant-table-thead > tr > th {
  font-weight: 600;
}

.ant-input-number {
  width: 100%;
}

.ant-input[type='color'] {
  width: 32px;
  padding: 0 2px;
  cursor: pointer;
}

.ant-input[type='color'] {
  width: 32px;
  padding: 0 2px;
  cursor: pointer;
}

.ant-image-preview-img {
  max-height: 90%;
  max-width: 90%;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  object-fit: cover;
}

.range-picker input {
  text-align: center;
  font-weight: bold;
}

/* PopConfirm */
.ant-popover-message,
.ant-popover-buttons {
  text-align: center;
}

.ant-popover-message > .anticon {
  position: static;
  font-size: 32px;
  margin-bottom: 8px;
}
